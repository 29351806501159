// Customizable Area Start
import React from "react";

import { styled, Box, Typography, TextField, InputLabel, Select, MenuItem, Button, Modal, IconButton, Paper} from "@mui/material"
import { contantusImage, dropDownArrow, SuccessIcon } from "./assets";
import Footerbar from "../../../components/src/Footerbar"
import CloseIcon from '@mui/icons-material/Close';

import ContactusPageController, { Props } from "./ContactusPageController.web";

export default class ContactusPage extends ContactusPageController {
	constructor(props: Props) {
		super(props);
	}

	renderSuccessModal = () => {
		return (
    <ModalContainer open={this.state.isSuccessModalOpen} onClose={() => this.handleModalClose()} data-testId="successModal" disablePortal disableEnforceFocus disableAutoFocus>
       <Paper className="paper_upload paper_success">
        <IconButton
          onClick={() => this.handleModalClose()}
          data-testId="closeSuccessTestID"
          className="closeIconBtn"
        >
          <CloseIcon />
        </IconButton>
        <UploadHeadBox>
            <BoldHeadTypo data-testId="closeSuccessLabelTestID">Thank You!</BoldHeadTypo>
            <img src={SuccessIcon} className="succes_image" />
						<Typography className="request_txt">Request received</Typography>
							<Button className="back-to-home-button" variant="contained" fullWidth onClick={() => this.handleModalClose()} data-testId="CloseModalBtn">Back to Home</Button>
						<Box>
						</Box>
        </UploadHeadBox>
      </Paper>
    </ModalContainer>)
	}

	render() {
			return (
				<>
					<MainContainer data-testId="contactUsPage">
						<ContactUsLeft>
							<Box>
								<Typography className="contact-us-name">Contant us</Typography>
								<Typography className="contact-us-dic">Please fill out the form and we will contact you as soon as possible</Typography>
							</Box>
							<Box className="form">
								<Box>
									<InputLabel className="field-top-name">Name</InputLabel>
									<TextField value={this.state.name} onChange={(e:{target:{value:string}}) => this.setState({name:e.target.value})} id="outlined-basic" placeholder="Enter your name" variant="outlined" fullWidth data-testId="nameField"/>
								</Box>
								<Box>
									<InputLabel className="field-top-name">Email Address</InputLabel>
									<TextField value={this.state.email} onChange={(e:{target:{value:string}}) => this.setState({email: e.target.value})} id="outlined-basic" placeholder="Email Address" variant="outlined" fullWidth data-testId="emailField"/>
								</Box>
								<Box>
									<InputLabel className="field-top-name">Request subject</InputLabel>
									<Select value={this.state.selectedOption || ""} onChange={(event: {target: {value: string}}) => this.handleSelections(event.target.value)} placeholder="Select" fullWidth IconComponent={() => <img src={dropDownArrow}/>}>
                    {this.state.requestOptions.map((option, index) => <MenuItem value={option} key={index}>{option}</MenuItem>)}
									</Select>
								</Box>
								<Box>
									<InputLabel className="field-top-name">Additional Information</InputLabel>
									<TextField value={this.state.additionalInformation} onChange={(e: {target:{value:string}}) => this.setState({additionalInformation: e.target.value})} id="outlined-basic" placeholder="Share any additional information" variant="outlined" multiline rows={3} fullWidth/>
								</Box>
								<Box className="tcCheckbok">
									<input checked={this.state.termsAndConditionCheckbox} onChange={this.handleCheckboxChange} type="checkbox" className="checkbox" data-testId="inputCheckBox"/>
									<Typography className="TandCCheckboxText">By sending this you agree with DatAi's <span className="blueTxt">Privacy Policy</span> and <span className="blueTxt">Terms and Conditions</span></Typography>
								</Box>
								<Box>
									<Button className="send-button" variant="contained" fullWidth onClick={this.handleQuerySend.bind(this)} data-testId="sentBtn">Send</Button>
								</Box>
							</Box>
						</ContactUsLeft>
						<ContactUsRight>
							<img className="banner-image" src={contantusImage} alt="placeholder" />
							<Typography className="image-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper</Typography>
						</ContactUsRight>
						{this.renderSuccessModal()}
				</MainContainer>
				<Footerbar isActive={false}/>
				</>
			)
	}
  
}


const MainContainer = styled(Box)({
  margin:'auto',
	marginTop:'50px',
	maxWidth:'1184px',
	width:'100%',
	display:'flex',
	justifyContent:'space-between',
	alignItems:'center',
	"@media (max-width: 1000px)": {
		flexDirection:'column-reverse',
		justifyContent:'center',
  },
})

const ContactUsLeft = styled(Box)({
	maxWidth:'464px',
  display:'flex',
  flexDirection:'column',
  gap:'36px',
	margin:'0 16px 32px 16px',
  "& .contact-us-name": {
    color: '#364AC6',
    fontSize:'32px',
    fontFamily:'Open sans',
    fontWeight: 600
  },
  "& .contact-us-dic": {
    color: '#4D4D4D',
    fontSize:'16px',
    fontFamily:'Open sans',
    fontWeight: 400,
    marginTop:'8px'
  },
	"& .send-button": {
		backgroundColor: '#000', 
    color: '#fff',
		fontSize:'16px',
		fontWeight:500,
		fontFamily:'Open sans',
    borderRadius: '8px',
		textTransform:'capitalize',
		display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
		height: '44px',
    '&:hover': {
      backgroundColor: '#222',
    },
	},
	"& .form": {
		display: 'flex',
		flexDirection:'column',
		gap:'12px',
	},
	"& .field-top-name": {
		color: "#4D4D4D",
		fontSize:'14px',
		fontWeight: 400,
		fontFamily: 'Open sans'
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#DDDDDDDD',
			borderRadius: '8px'
		},
		'&:hover fieldset': {
			borderColor: 'black',
		},
		'&.Mui-focused fieldset': {
			borderColor: 'black',
			borderWidth: '1px'
		},
	},
	'::placeholder': {
		color:'#B3B3B3',
		fontWeight:400,
		fontSize:'14px',
		fontFamily:'Open sans'
	},
	'& .tcCheckbok':{
		display:'flex',
		alignItem: 'start',
		'& .checkbox':{
			width:'22px',
			height:'22px',
			outline: 'none',
			border: '1px solid #c8c8c8',
			borderRadius:'4px',
			backgroundColor:'#fff',
			color:'greens'
		}
	},
	'& .TandCCheckboxText':{
		marginLeft:'10px',
		fontSize:"14px",
		fontWeight:400,
		fontFamily:'Open Sans',
		"& .blueTxt":{
			color:'blue',
			cursore:'pointer'
		}
	},
	"@media (max-width: 1000px)": {
		justifyContent:'center',
  },
})

const ContactUsRight = styled(Box)({
	margin: '12px',
	maxWidth:'600px',
	display:'flex',
	flexDirection:'column',
	"& .image-text": {
		color:'#4D4D4D',
		fontSize:"16px",
		fontWeight:400,
		fontFamily:"Open sans"
	},
	"& .banner-image":{
		width:'100%',
		maxWidth:'600px',
	},
	"@media (max-width: 1000px)": {
		justifyContent:'center',
  },
})

const ModalContainer = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  margin: "20px 0px",
  "& .paper": {
    maxHeight:'fit-content',
    width:"fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      lineHeight: "27px",
      fontWeight: 600,
      fontSize: "20px"
    },
    "& .MuiTypography-body2": {
        color: "#4D4D4D",
        lineHeight: "21px",
        fontWeight: 400,
        fontSize: "16px",
        maxWidth: "500px",
        textAlign: "center"
      },
      '@media (max-width: 375px)': {
        padding: "20px",
      },
  },
  "& .paper_success":{
    padding: "40px !important",
  },
  "& .paper_upload": {
    maxHeight:'fit-content',
    width:"600px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
    '@media (max-width: 775px)': {
      width: "550px"
    },
    '@media (max-width: 675px)': {
      width: "500px"
    },
    '@media (max-width: 575px)': {
      width: "450px"
    },
    '@media (max-width: 475px)': {
      width: "350px"
    },
    '@media (max-width: 375px)': {
      width: "280px",
      padding: "20px",
    },
  },
  "& .renamepaper": {
    "& .MuiOutlinedInput-input": {
        padding: "4px !important",
        borderRadius: "8px",
        border: "1px solid #DDDDDD",
        width: "100%"
    }
},
  "& .closeIconBtn": {
    position: "absolute",
    color: "black",
    top: "10px",
    right: "10px"
  },
  "& .buttonBox": {
    display: "flex",
    gap: "20px",
    padding: "20px 0px",
    "& .MuiButton-root": {
        textTransform : "none",
        borderRadius: "8px",
        padding: "10px 16px",
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "16px",
      width: "212px",
      height: "48px",
      '@media (max-width: 675px)':{
        width:"200px !important"
        },
      '@media (max-width: 575px)':{
        width:"180px !important"
        },
      '@media (max-width: 475px)':{
         width:"130px !important"
        },
      '@media (max-width: 375px)':{
         width:"100px !important",
         padding:"10px 5px"
       },
     },
    "& .cancleBtn": {
        color: "#000000",
        border: "1px solid #000000"
    },
    "& .deleteBtn": {
        color: "white",
        backgroundColor: "#000000"
    }
  }
});

const BoldHeadTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 600,
  textAlign:"center",  
  fontSize: '20px',
  color: '#4D4D4D',
})

const UploadHeadBox = styled(Box)({
  display: "flex",
  justifyContent: "end",
  width: '100%',
  flexDirection: "column",
  alignItems: "center",
  "& .succes_image":{
     width: "80px",
     height: "80px",
		 margin: '40px auto'
  },
	"& .request_txt": {
		color:'#4D4D4D',
		fontSize:'16px',
		fontWeight: 400,
		marginBottom:'25px'
	},
	"& .back-to-home-button":{
		backgroundColor: '#000', 
    color: '#fff',
		fontWeight:500,
		fontFamily:'Open sans',
		fontSize:'16px',
    borderRadius: '8px',
		textTransform:'capitalize',
		display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
		height: '44px',
    '&:hover': {
      backgroundColor: '#222',
    },
	}
})

// Customizable Area End